@import 'src/styles/config';
.root {
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #fff;
}
.inner {
  height: $header-height;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 0.8rem;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  margin-left: -1.6rem;
  padding-left: 1.6rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    position: relative;
    box-shadow: none;
    height: calc($header-height * 1.78);
  }
  .wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0 0.6rem;
    background-color: #fff;
    box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    padding: 0 1.6rem;
    margin-left: 0;
    align-items: center;
    display: grid;
    min-height: 5rem;
    border-bottom: 1px solid $color-grey-lines;
  }
}
