//$color-primary: #1e2844;
//$color-primary-light: rgba($color-primary, 0.6);
//
//$color-secondary: #98ce7b;
//$color-secondary-dark: #65aa40;

$color-grey-dark: #393939;
//$color-grey: #9c9c9c;
$color-grey-icon: #c5cfd3;
$color-grey-lines: #c2c2c2;
$color-grey-light: #f8f8f8;
$color-grey-light-2: #f5f6f7;

$color-red: #e12037;
$color-green: #5faf59;
$color-blue: #5997c0;
$color-orange: #f49511;

//new colors
$color-primary: #5997c0;
$color-primary-light: rgba($color-primary, 0.6);
$color-primary-dark: #366d9d;

$color-secondary: #009f19;
$color-secondary-dark: #008214;

$color-grey: #707070;
$color-light-blue: #f2f9ff;
$color-grey-old: #c0c0c0;

$color-chart-start: #008214;
$color-chart-end: #366d9d;
