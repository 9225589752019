@import 'src/styles/config';
.root {
  max-width: $panel-width;
  padding: 0 0.5rem;
  display: grid;
  grid-gap: 0.5rem;
}

.item {
  border-radius: 0.4rem;
  opacity: 0.5;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  justify-content: center;
  padding: 0.6rem 0.1rem;
  &:global {
    &.Mui-selected {
      background-color: rgba(#fff, 0.2);
      opacity: 1;
    }
  }
}
