@import 'src/styles/config';

.root {
  display: grid;
  grid-template-columns: 23rem 1fr;
  height: 100%;
  border-radius: 0.8rem;
  border: 1px solid $color-grey-lines;
  background: #fff;
  overflow: hidden;
  & > * {
    min-width: 0;
  }
}

.wrap {
  padding: 1.3rem 2rem 2rem;
}
.tabs {
  background: $color-grey-light;
  padding: 0.9rem 0;
  &Indicator {
    width: 0.3rem;
  }
}
.tab {
  align-items: flex-start;
  text-transform: capitalize;
  border-bottom: 1px solid $color-grey-lines;
  min-width: inherit;
  font-weight: normal;
  padding: 1.05rem 2rem;
  min-height: 3.9rem;
  text-align: start;
  &:first-child {
    border-top: 1px solid $color-grey-lines;
  }
}
.tabSelected {
  background: #fff;
  color: $color-secondary-dark !important;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  .tabs {
    padding: 0;
  }
  .tab {
    border: 0;
    border-right: 1px solid $color-grey-light;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    border: none;
    background-color: transparent;
  }
  .wrap {
    padding: 1rem 0 0;
  }
  .tabs {
    min-height: 3.6rem;
  }
  .tab {
    min-height: 3.6rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}
