@import 'src/styles/config/colors';

$pieces: 7;
$delta: 0.2s;

.root {
  animation: spin 1.4s infinite;
  & > * {
    animation: circle 1.4s infinite;
  }
  @for $i from 0 through $pieces {
    :nth-child(#{$i}) {
      animation-delay: calc($i * $delta);
    }
  }
}
$color1: #b0d89b;
$color2: #369d49;

@keyframes circle {
  @for $i from 0 through $pieces {
    $percent: calc($i * 100 / $pieces);
    #{$percent}% {
      fill: mix($color1, $color2, $percent);
    }
  }
}

$frames: 24;
@keyframes spin {
  @for $k from 0 through $frames {
    $per: calc($k * 100 / $frames);
    #{$per}% {
      transform: rotate(0deg - calc($per * 3.6));
    }
  }
}
